import React, { useState } from "react";
import styled from "@emotion/styled";
import { Tree, TreeNode } from "react-organizational-chart";
import { css } from "@emotion/react";
import LogoCEIRD from "../src/Img/LogoCEIRD.png";
import Firma_y_Sello from "../src/Img/Firma_y_Sello.png"


// Componente estilizado para los nodos del organigrama
const StyledNode = styled.div`
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  font-weight: 300;
  color: #ffffff; /* Color del texto */
  background-color: #007bff; /* Azul primario de Bootstrap */
  border-radius: 8px;
  padding: 5px;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: #0099ff; /* Color de fondo al pasar el mouse */
  }
`;

const StyledNodeColorGreen = styled.div`
font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
font-size: 12px;
font-weight: 300;
color: #ffffff; /* Color del texto */
background-color: #28a745; /* Verde éxito de Bootstrap */
border-radius: 8px;
padding: 5px;
display: inline-block;
cursor: pointer;
&:hover {
  background-color: #218838; /* Color de fondo al pasar el mouse */
}
`;

const StyledNodeColorGray = styled.div`
font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
font-size: 12px;
font-weight: 300;
color: #ffffff; /* Color del texto */
background-color: #6c757d; /* Gris secundario de Bootstrap */
border-radius: 8px;
padding: 5px;
display: inline-block;
cursor: pointer;

&:hover {
  background-color: #5a6268; /* Color de fondo al pasar el mouse */
}
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998;
`;

const ModalWrapper = styled.div`
  display: ${props => (props.show ? "block" : "none")};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  max-height: 70vh;
  width: 550px; /* Aumentamos el ancho del modal */
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #fff;
  z-index: 9999;
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
`;

const ModalTitle = styled.h2`
  color: #333;
  text-align: center;
`;

const ModalCloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px; /* Aumentamos el tamaño del botón cerrar */
  transition: color 0.2s, background-color 0.2s; /* Agregar una transición para suavizar el cambio */

  &:hover {
    color: navy; /* Cambiar a color azul oscuro en el hover */
    background-color: #f0f0f0; /* Cambiar el fondo a un tono más claro en el hover */
  }

transition: .3s ease all;
border-radius: 5px;
color: navy;

&:hover {
    background: #f2f2f2;
}

svg {
    width: 30px;
    height: 30px;
}
`;


const ModalContent = styled.div`

  align-items: flex-start; 
  p {
    margin-bottom: 10px;
  }

  title {
    font-weight: bold;
    color: navy;
  }
  
  text {
    color: darkblue;
    font-weight: bold;
  }
  Strong {
    color: darkblue;
  }

`;

const MainContainer = styled.div`
  top: 20px;
  display: block;
  flex-direction: column;
  align-items: center;
`;

const ModalImage = styled.div`
  margin-right: 20px;
  flex: 0 0 130px; /* Establecemos un ancho fijo para la imagen */
  height: 130px; /* Establecemos una altura fija para la imagen */
`;

//Componente Modal reutilizable
const Modal = ({ title, imageSrc, content, onClose }) => (
  <>
    <ModalOverlay onClick={onClose} />
    <ModalWrapper show={true}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <ModalCloseButton onClick={onClose}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg></ModalCloseButton>
      </ModalHeader>
      <ModalContent>
      <div style={{ display: "flex" }}>
        <ModalImage>
          <img src={imageSrc} alt="Profile" width="100%" height="100%" style={{ borderRadius: "50%" }} />
        </ModalImage>
        <div>
          {content.map((item, index) => (
            <p key={index}>
              <strong>{item.title}</strong>
              <span>{item.text}</span>
            </p>
          ))}
        </div>
      </div>
    </ModalContent>
    </ModalWrapper>
  </>
);

const OrganizationalChart = () => {
  // State para controlar la visibilidad del modal y la información a mostrar
  const [modalData, setModalData] = useState(null);

  // Información de ejemplo para el modal
  const modalDataMap = {
    "Dirección Ejecutiva": {
      title: "Director Ejecutivo",
      imageSrc: require("./Img/BIVIANA-RIVEIRO.jpg").default, 
      content: [
        { title: "Nombre: ", text: "Angelina Biviana Riveiro Disla" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de laRepública Dominicana (CEI-RD); Decreto núm. 329-20, Art. 10." },
        {
          title: "Funciones: ",
          text: `  `,
        },
        {text: `1. Elaborar y someter a la consideracion  reglamentos internos y generales de la institución, presentar las memorias y balance anuales.
       `,},
        {text: `2. Representar legalmente al Centro de Exportación e Inversión de la República Dominicana (CEI-RD), ante terceros y en justicia, pudiendo en tal calidad, firmar validamente toda clase de contratos y documentos, salvo cuando estos representen comprornisos para el Gobierno Dominicano.
       `,},
       {text: `3. Gestionar las asignaciones presupuestarias y otros recursos financieros necesarios para el funcionamiento de la institución.
       `,},
       {text: `4. Crear y desarrollar mecanismos de coordinación, con instituciones y organismos tanto del sector público como privado, a nivel nacional e internacional, a los fines de mejorar y optimizar las actividades de promoción de exportaciones e inversiones.
       `,}
        ,
       {text: `5. Supervisar la ejecución presupuestaria.
        `,}
       ,
       {text: `6. Identificar y gestionar fondos que puedan ser utilizados para cubrir programas de interés para el desarrollo de las actividades de promoción de las exportaciones y la captación de inversión extranjera directa.
       `,}
       ,
       {text: `7. Participar en actividades que propicien el desarrollo de nuevas oportunidades de financiamiento a los sectores productivos, actuales y de nuevo interés en el sector, de acuerdo a las oportunidades para la inversión extranjera.
       `,}
       ,
       {text: `8. Elaborar y gestionar proyectos de alianzas estratégicas y cooperación internacional.
       `,}
       ,
       {text: `9. Coordinar la elaboración de los informes técnicos sobre los avances, resultados y recomendaciones de la gestión de la Subdirección General sobre los proyectos de las actividades planificadas y ejecutadas.
       `,}
      ],
    },
    "Subdirección General": {
      title: "Subdirector (a) General",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Vladimir Pimentel Florenzán" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD); Decreto núm. 356-20, Art. 1." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Crear y desarrollar mecanismos de coordinación, con instituciones y organismos tanto del sector público como privado, a nivel nacional e internacional, a los fines de mejorar y optimizar las actividades de promoción de exportaciones e inversiones.
         `,}  
          ,
        {text: `
           2. Supervisar la ejecución presupuestaria.
          `,}
            ,
        {text: `
              3. Identificar y gestionar fondos que puedan ser utilizados para cubrir programas de interés para el desarrollo de las actividades de promoción de las exportaciones y la captación de inversión extranjera directa.
              `,}
              ,
        {text: `4. Participar en actividades que propicien el desarrollo de nuevas oportunidades de financiamiento a los sectores productivos, actuales y de nuevo interés en el sector, de acuerdo a las oportunidades para la inversión extranjera.
        `,}
                ,
        {text: `5. Elaborar y gestionar proyectos de alianzas estratégicas y cooperación internacional.
                  .`,}
                  ,
        {text: `6. Coordinar la elaboración de los informes técnicos sobre los avances, resultados y recomendaciones de la gestión de la Subdirección General sobre los proyectos de las actividades planificadas y ejecutadas.`,}     ],
    },

    "Subdirección Técnica": {
      title: "Subdirector (a) Técnico (a):",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Mildred Walquidia Santos Santos" },
        { title: "Normativa: ", text: "Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)" },
        {
          title: "Funciones: ",
          text: ` `,

        },
        {text: `1. Elaborar y aprobar los planes operativos anuales del área.
       `,}
       ,
        {text: `2. Gestionar la mejora continua en materia de políticas, procesos y procedimientos institucionales.
       `,}
       ,
        {text: `3. Coordinar la elaboración del Plan Estratégico de la Institución, velando por el cumplimiento del mismo.
       `,}
       ,
        {text: `4. Velar por el cumplimiento del sistema de gestión de calidad.
       `,}
       ,
        {text: `5. Elaborar propuestas de revisión de las estructuras organizativas y de reingeniería de procesos, incluyendo los componentes tecnológicos que correspondan.
         `,}
       ,
       {text: `6. Validar y dar seguimiento a los proyectos de Cooperación Internacional que impactan a la Institución en conjunto con la Gerencia de Planificación y Gestión. 
      `,}
       ,
        {text: ` 7. Validar y evaluar los expedientes de las solicitudes de clasificación para los incentivos de la Ley 84-99 y el Decreto No. 334-07 que establece el reglamento para el comercio y la exportación de desperdicios de metales, chatarras y otros desechos.
       `,}
       ,
        {text: `8. Aprobar las certificaciones del exportador y de Inversión Extranjera Directa (IED).
       `,}
       ,
        {text: `9. Validar y aprobar las normativas internas relativas al funcionamiento de las actividades vinculadas a la Dirección de Negocios y la Dirección de Estudios Económicos y Estadística.
       `,}
       ,
        {text: `10. Coordinar y evaluar la elaboración de los informes técnicos sobre los avances, resultados y recomendaciones de la gestión de la Subdirección Técnica sobre los proyectos de las actividades planificadas y ejecutadas.
       `,}
       ,
        {text: `11. Aprobar la participación de la Institución en los espacios interinstitucionales referidos a actividades de la Dirección Estudios Económicos y Estadística.
       `,}
       ,
        {text: `12. Representar al Director Ejecutivo por sustitución en caso de ausencia o por delegación en los eventos, reuniones y actividades requeridas para cumplir con los compromisos institucionales.
       `,}
      ],
    },

    "Consultoría Jurídica": {
      title: "Consultor (a) Jurídico (a):",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Anel Lluberes" },
        { title: "Normativa: ", text: "Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de laRepública Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,

        },
        {text: `1. Realizar investigaciones y análisis en el orden jurídico, para resolver asuntos legales propios de la institución.
       `,}
       ,
        {text: `2. Preparar, redactar, analizar y evaluar la documentación requerida por las diferentes Instancias internas y externas. `,}
,
        {text: `3. Elaborar contratos a ser suscritos por el representante de la institución con personas físicas o morales, redacción de leyes, decretos, reglamentos y llevar registro y control de los mismos. `,}
,
        {text: `4. Intervenir en las reclamaciones y litigios que puedan afectar los intereses de la institución. `,}
,
      ],
    },
    "Dirección Innovación Estratégica": {
      title: "Director de Innovación Estratégica",
      imageSrc: require("./Img/EdgarEsmipinal.jpg").default, 
      content: [
        { title: "Nombre: ", text: "Edgar Espinal Quezada" },
        { title: "Normativa: ", text: "Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)" },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Liderar el proceso de formulación de las estrategias, metas, productos e indicadores de gestión institucionales, cumpliendo con las normativas y regulaciones dictadas por los organismos estatales vinculados. 
       `,}
       ,
        {text: `2. Promover una cultura de innovación y orientación al logro de los objetivos institucionales. 
       `,}
       ,
        {text: `3. Presentar propuestas de proyectos de mejora para contribuir con el fortalecimiento institucional y la provisión de servicios a clientes externos e internos. 
        `,}
       ,
        {text: `4. Identificar y definir los procesos de la Institución, estableciendo mejoras continuas y controlando la matriz de riesgo y generando mayor eficiencia de la gestión. 
       `,}
       ,
        {text: `5. Monitorear el cumplimiento/desempeño de los planes, procesos e indicadores del sistema de gestión.
       `,}
       ,
        {text: `6. Supervisar, analizar y comunicar a la Dirección Ejecutiva y a los incumbentes departamentales las métricas de ejecución para buscar oportunidades que mejoren el desempeño de la institución. 
        `,}
       ,
        {text: `7. Validar y aprobar las modificaciones presupuestarias que afecten la estructura programática de presupuesto y/o los productos definidos en el Plan Operativo. 
        `,}
       ,
        {text: `8. Participar y colaborar con las mesas e iniciativas interinstitucionales con otras dependencias del estado, según sea requerido y pertinente a la función. 
       `,}
       ,
        {text: `9. Realizar cualquier otra función afín o complementaria que le sea asignada por su superior inmediato.
       `,}
       
      ],
    },
    "Coordinación Planificación Y Gestión Institucional": {
      title: "Coordinador de Planificación y Gestión Institucional",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "-" },
        { title: "Normativa: ", text: "Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)" },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Formular planes estratégicos, políticas y programas necesarios para el desarrollo institucional en coordinación con las direcciones de la Institución.
       `,}
       ,
        {text: `2. Dar seguimiento a la formulación del presupuesto institucional y verificar su alineación con el Plan Estratégico.
       `,}
       ,
        {text: `3. Dirigir y organizar reuniones de análisis y revisión de la estrategia que se implementará en la Institución.
       `,}
       ,
        {text: `4. Colaborar en la definición de la estructura organizacional basada en la estrategia y procesos internos.
       `,}
       ,
        {text: `5. Apoyar el proceso de comunicar y difundir la estrategia corporativa a todos los niveles de la institución.
       `,}
       ,
        {text: `6. Coordinar la elaboración y revisión de los manuales de políticas y procedimientos de la institución.
       `,}
       ,
        {text: `7. Monitorear los indicadores del Sistema de Monitoreo y Medición de la Gestión Pública.
       `,}
      ],
    },
    "Gerencia de Proyectos De Innovación": {
      title: "Gerente de Proyectos De Innovación",
      imageSrc: require("./Img/SorangelDiaz.jpg").default, 
      content: [
        { title: "Nombre: ", text: "Sorangel Díaz" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)" },
        {
          title: "Funciones: ",
          text: ` `,
        }
        ,
        {text: `1. Diseñar e implementar la metodología de Gestión de Proyectos, acorde a la naturaleza de la Institución y las buenas prácticas reconocidas. 
       `,}
        ,
        {text: `2. Identificar y/o plantear alternativas de innovación para el desarrollo institucional y para la mejora de procesos, productos y servicios. 
       `,}
        ,
        {text: `3. Velar por la inserción de los proyectos en los planes operativos de la Institución. 
      `,}
        ,
        {text: `4. Gestionar instrumentos y herramientas que faciliten el trabajo y simplifiquen los procesos. 
        `,}
        ,
        {text: `5. Elaborar planes de gestión, según lo amerite cada proyecto. 
        `,}
        ,
        {text: `6. Crear el portafolio de proyectos priorizados, alineados al Plan Estratégico Institucional. 
        `,}
        ,
        {text: `7. Gestionar los proyectos desde su planificación hasta el cierre en función de los planteamientos estratégicos. 
         `,}
        ,
        {text: `8. Manejar las herramientas, métodos, métricas y los cronogramas maestros del proyecto.
         `,}
        ,
        {text: `9. Velar por la efectiva gestión del cronograma definido para cada proyecto, con el fin de que sean alcanzados los resultados establecidos en la programación de cada uno, de manera oportuna. 
         `,}
        ,
        {text: `10. Colaborar en la evaluación de potenciales proyectos, analizar su viabilidad y su conveniencia, según el enfoque estratégico de la Institución.
         `,}
        ,
        {text: `11. Gestionar instrumentos y herramientas innovadoras que faciliten el trabajo y simplifiquen los procesos. 
         `,}
        ,
        {text: `12. Velar porque los procedimientos desarrollados dentro de los proyectos estén alineados con los objetivos estratégicos de la Institución. 
        `,}
        ,
        {text: `13. Asegurar la implementación de los proyectos en tiempo, alcance y presupuesto planificado, gestionando las desviaciones que puedan darse en estas o en las demás restricciones de la gestión de proyectos. 
       `,}
        ,
        {text: `14. Preparar informes sobre el impacto logrado en el cumplimiento de los proyectos establecidos. 
        `,}
        ,
        {text: ` 15. Comunicar a la alta gerencia los riesgos identificados en los diversos proyectos previo análisis de matriz de riesgo. 
        `,}
        ,
        {text: `16. Informar a todos los actores involucrados sobre los avances o retrasos. 
        `,}
        ,
        {text: `17. Analizar y comunicar a la alta gerencia las métricas de ejecución del portafolio de proyectos.
         `,}
        ,
        {text: `18. Coordinar la elaboración y revisión de los manuales, políticas y procedimientos de la institución. 
         `,}
        ,
        {text: `19. Realizar cualquier otra función afín o complementaria que le sea asignada por su superior inmediato.
        `,}
        ,
        
      ],
    },

    "Gerencia De Tecnología de la información": {
      title: "Gerente de Tecnología de la Información",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "-" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)" },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Planificar, diseñar, ejecutar y monitorear la estrategia de tecnologías de la información.
         `,},
       {text: `2. Planificar y coordinar la implementación y funcionamiento efectivo de los servicios de base de datos, sistemas operativos, respaldos y aplicaciones en la infraestructura tecnológica con la finalidad de mantener y mejorar los servicios prestados por la institución.
       `,}
       ,
       {text: `3. Evaluar las propuestas de tecnología asociadas a la red, así como proponer nuevas alternativas para su diseño.
      `,}
       ,
       {text: `4. Participar en la elaboración de los Planes de Contingencia con el fin de mejorar la capacidad de respuesta ante eventos que interrumpan la prestación de servicios de la institución.
       `,}
       ,
       {text: `5. Proponer la infraestructura de hardware y software más adecuada para atender las necesidades de la institución.
       `,}
      ],
    },

    "Dirección Relaciones Internacionales": {
      title: "Director (a) de Relaciones Internacionales",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Maritza Isabel Bello Martínez" },
        { title: "Normativa: ", text: "Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Supervisar de forma directa las actividades y operaciones de los ejecutivos de la red internacional.
        `,}
       ,
        {text: `2. Supervisar la red de representantes directos adscritos al MIREX y a ProDominicana; Servir de enlace entre la red y los demás equipos de la institución y gestionar las herramientas promocionales a utilizar por la red internacional.
        `,}
       ,
        {text: `3. Supervisar la implementación del plan de promoción comercial de la red internacional alineado al plan de estratégico institucional.
        `,}
       ,
        {text: `4. Crear mecanismo de medición para la red internacional y supervisar la correcta actualización de los datos.
        `,}
       ,
        {text: `5. Apoyar en la captación de potenciales compradores y potenciales inversionistas; Velar por el cumplimiento de la política de calidad y los procedimientos establecidos por ProDominicana.
        `,}
       ,
        {text: `6. Presentar informes periódicos de resultados de desempeño del área.
       `,}
       ,
        {text: `7. Trabajar de la mano con el Viceministerio de Asuntos Económicos y Cooperación Internacional del Ministerio de Relaciones Exteriores en los temas relacionados a los miembros de la Red Internacional pertenecientes al MIREX.
         `,}
       ,
        {text: `8. Brindar apoyo logístico a las misiones diplomáticas y consulares para el adecuado cumplimiento de sus funciones de promoción de la oferta exportable y atracción de inversión.
       `,}
      ],
    },

    "Red Internacional": {
      title: "Gerente de Red Internacional",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Roselin Oneil Pimentel" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Establecer relaciones interinstitucionales para contribuir con el desarrollo de los proyectos que inciden en el crecimiento y desarrollo del país.
       `,}
       ,
        {text: `2. Recibir y canalizar informes elaborados por la dirección de inteligencia de mercados sobre las actividades económicas y comerciales en los países en que operan la red internacional, a fin de que estudien las oportunidades de negocios y la competitividad de la oferta exportable dominicana.
       `,}
       ,
        {text: `3. Identificar oportunidades de mejora del sector exportador dominicano.
       `,}
       ,
        {text: `4. Organizar conferencias de avanzado nivel a favor de la promoción del país.
          `,},
        {text: `5. Asumir la representación técnica de la Institución en los eventos privados y oficiales, relacionados directamente con el sector Exportador.
      `,},
        {text: `6. Brindar apoyo a las áreas de la dirección de exportación e inversión en la coordinación de misiones, ferias y eventos; Participar en los procesos de negociaciones comerciales y proyectos de avanzado interés para la institución.
      `,},
        {text: `7. Coordinar con la elaboración y actualización del Plan de Promoción Comercial y dar seguimiento, el cual esté alineado al plan de trabajo de la Institución de la mano con el Viceministerio de Asuntos Económicos y Cooperación Internacional del Ministerio de Relaciones Exteriores.
 `,}
      ],
    },

    "Cooperación Internacional": {
      title: "Gerente de Cooperación Internacional",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Nelsy Patricia Peña" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Ejecutar el plan operativo anual de la gerencia, cumpliendo con los compromisos programados Coordinar con las diferentes direcciones de la institución el proceso de identificación de proyectos con necesidades de cooperación financiera no reembolsable y técnico-científica a presentar ante los organismos cooperantes.
        `,}
       ,
        {text: `2. Gestionar y participar en captación de asistencia técnica, proveniente de la cooperación nacional y multinacional, en las áreas de interés de la Institución, así como realizar las actividades necesarias, para la suscripción y ejecución de los convenios de cooperación.
        `,}
       ,
        {text: `3. Asesorar en el proceso de formulación de documentos de asistencia técnica e infraestructura, conforme a lineamientos emitidos por los diferentes organismos cooperantes.
       `,}
       ,
        {text: `4. Identificar fuentes de cooperación de organismos multilaterales.
        `,}
       ,
        {text: `5. Servir de enlace entre el Viceministerio de Cooperación Internacional y ProDominicana para realizar gestiones de aprobación y seguimiento de proyectos de cooperación.
        `,}
       ,
        {text: `6. Presentar a la Dirección Ejecutiva las solicitudes de cooperación técnico-financierainanciera no reembolsable, para aprobación.
       `,}
       ,
        {text: `7. Establecer contactos con cooperantes potenciales de la Institución.
        `,}
       ,
        {text: `8. Asistir a reuniones representando a la Institución ante organismos nacionales e internacionales para la gestión de cooperación.
       `,}
      ],
    },

    "Dirección Del Talento Humano y Servicio": {
      title: "Director (a) del Talento Humano y Servicios",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Astrid Díaz" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Liderar el proceso de Planificación de Gestión del Talento Humano.
        `,}
       ,
        {text: `2. Diseñar programas, estrategias, políticas y procedimientos que busquen desarrollar la calidad y eficiencia de los recursos humanos de la Institución.
        `,}
       ,
        {text: `4. Velar por la actualización de los manuales que administra la Gerencia.
        `,}
       ,
        {text: `5. Revisar la estructura salarial y diseñar propuestas del programa de incentivos y beneficios para el personal y velar su cumplimiento.
       `,}
       ,
        {text: `6. Supervisar, validar e implementar el proceso de rediseño de la estructura organizacional.
       `,}
       ,
        {text: `7. Participar y supervisar en la selección y contratación del personal. 
       `,}
       ,
        {text: `8. Asesorar y ayudar a los directores y gerentes con la contratación y formación del personal.
       `,}
       ,
        {text: `9. Revisar y validar la evaluación anual del desempeño a todo el personal, a los fines de fomentar la mejora de los colaboradores en el logro de los objetivos Institucionales.
        `,}
       ,
        {text: `10. Revisar y validar el plan semestral o anual de formación y desarrollo de la organización.
       `,}
      ],
    },

    "Formación y Desarrollo (Interna y Externa)": {
      title: "Gerente de Formación y Desarrollo",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Susana Emperatriz Antón Espinal" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        }
       ,
        {text: `1. Dirigir el proceso de detección de necesidades de capacitación.
        `,}
       ,
        {text: `2. Diseñar programas de formación que contribuyan con el desarrollo de las competencias de las empresas vinculadas a las actividades de exportación.
        `,}
       ,
        {text: `3. Diseñar programas de formación que contribuyan con el desarrollo de las competencias de los colaboradores de ProDominicana.
        `,}
       ,
        {text: `4. Planificar y evaluar las capacitaciones de los exportadores, los potenciales exportadores, las pymes y los relacionados al comercio internacional.
        `,}
       ,
        {text: `5. Elaborar el plan anual de capacitación interna y externa.
        `,}
       ,
        {text: `6. Elaborar el presupuesto anual de capacitación interna y externa.
       `,}
       ,
        {text: `7. Revisar los acuerdos interinstitucionales donde interviene el tema de capacitación.
       `,}
       ,
        {text: `8. Coordinar el trabajo de las actividades de capacitación en conjunto con otras instituciones.
        `,}
       ,
        {text: `9. Dirigir y promover el programa institucional de desarrollo de la Cultura Exportadora.
         `,}
       ,
        {text: `10. Coordinar y ejecutar el programa de Coaching Exportador.
       `,}
       ,
        {text: `11. Gestionar el mejoramiento de la plataforma virtual de formación, de cara a las mejores prácticas."
       `,}
      ],
    },

    "Dirección Administrativa y Financiera": {
      title: "Director (a) Administrativa y Financiera",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: " Karina Marcelle Ortíz Cabral" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Dirigir y supervisar la implementación de la estrategia institucional y de los planes operativos en las gerencias de apoyo garantizando la existencia de los controles adecuados para el manejo de los recursos.
        `,}
       ,
        {text: `2. Revisar las propuestas de planes anuales de presupuesto, compras y contrataciones.
       `,}
       ,
        {text: `3. Revisar la programación propuesta en los planes operativos de cada gerencia del área.
       `,}
       ,
        {text: `4. Asegurar la implementación de procedimientos eficaces administrativos y financieros que permitan ejercer los controles necesarios y faciliten la ejecución de los procesos de la Institución.
       `,}
       ,
        {text: `5. Planificar y coordinar las actividades de sistematización y comunicación de la Institución asegurando la adquisición, programación y mantenimiento adecuado de los equipos y/o aplicaciones que satisfagan los requerimientos de tecnología de la institución.
       `,}
       ,
        {text: `6. Coordinar la relación operativa entre las gerencias a su cargo, entre sí y con el resto de la Institución; Validar las emisiones de pagos y nóminas presentadas por la Gerencia de Administración y Finanzas.
       `,}
      ],
    },
   
    "Administrativa": {
      title: "Gerente Administrativo",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Lina Ricart" },
        { title: "Normativa: ", text: "Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        }
        ,
        {
          text: `1. Establecer controles eficientes sobre el proceso administrativo.
          `,
        },
        {
          text: `2. Mantener informado al personal de la dependencia acerca de las políticas, normas y procedimientos y decisiones de carácter administrativo.
           `,
        }
        ,
        {
          text: `3. Apoyo directo al departamento de compras en los procesos.
          `,
        }
        ,
        {
          text: `4. Participar en la elaboración del presupuesto y distribución anual.
          `,
        }
        ,
        {
          text: `5. Manejo del combustible a distribuir a los colaboradores de la Institución.
           `,
        }
        ,
        {
          text: `6. Colaboración con la elaboración del PACC de la Institución.
        `,
        }
        ,
        {
          text: `7. Apoyo en la ejecución de los fondos manejados por el PNUD mediante acuerdo entre Instituciones.`,
        }
        ,
        {
          text: `8. Garantizar los servicios, tanto de mensajería interna, como externa, asegurando y dando seguimiento a los procesos de recepción, control, distribución y archivo de la correspondencia y paquetería.
         `,
        }
        ,
        {
          text: `9. Asegurar el correcto uso y custodia del archivo Institucional de la correspondencia recibida.
         `,
        }
        ,
        ,
        {
          text: `10. Planificar y dar seguimiento a los procesos de recepción y distribución del material gastable. `,
        }
      ],
    },

    "Financiera": {
      title: "Gerente Financiero:",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Dahiana Cordero" },
        { title: "Normativa: ", text: "Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de laRepública Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,

        },
        {text: `1. Funciones principales: Coordinar y elaborar los Planes anuales de Presupuesto de Compras y Contrataciones.
       `,}
       ,
        {text: `2. Proponer y elaborar el Plan Operativo Anual del área, garantizando el control adecuado del manejo de los recursos financieros.
        `,}
       ,
        {text: `3. Velar por el cumplimiento de las normativas impositivas y de registro contable exigidos por las leyes tributarias del país y por la Contraloría General de la República.
       `,}
       ,
        {text: `4. Coordinar con la Dirección Ejecutiva y las Direcciones el establecimiento de prioridades en la distribución de los recursos financieros, materiales y de logística requeridos para cumplir cabalmente con los objetivos, metas y estrategias a desarrollar para el cumplimiento del plan estratégico del CeiRD.
        `,}
       ,
        {text: `5. Velar que los registros y resultados de las operaciones contables realizadas, y la confección de los estados financieros, sean realizados correctamente a fin de recomendar medidas tendientes a optimizar resultados.
       `,}
       ,
        {text: `6. Supervisar el proceso de emisión de pagos y de nóminas, garantizando el depósito oportuno de los mismos.
       `,}
       ,
        {text: `7. Garantizar el debido control bancario de los recursos financieros de la Institución mediante la correcta administración de las cuentas bancarias.
       `,}
       ,
      ],
    },

    "Servicios Generales": {
      title: "Gerente de Servicios Generales",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Edwin Rafael Grullón Monzon" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        }
       ,
        {text: `1. Definir, conjuntamente con la Dirección Administrativa y Financiera, las prioridades en la distribución de los materiales, recursos logísticos y servicios requeridos para cumplir con los objetivos de la organización.
        `,}
       ,
        {text: `2. Proponer y formular las políticas, normas y procedimientos de servicios generales y de apoyo logístico de las actividades de la Institución.
       `,}
       ,
        {text: `3. Velar y asegurar el buen estado y limpieza de las instalaciones, equipo y mobiliario de la Institución.
        `,}
       ,
        {text: `4. Velar y asegurar la ejecución del plan de limpieza preventivo y correctivo al mobiliario, equipo de oficina y vehículos de transporte.
       `,}
       ,
        {text: `5. Asegurar el cumplimiento y respuesta oportuna a los requerimientos de servicios de transportación requeridos por las unidades organizativas de la Institución.
       `,}
       ,
        {text: `6. Velar por el correcto uso de los espacios de la Institución para la realización de las actividades programadas.
        `,}
       ,
        {text: `7. Asegurar y monitorear el apoyo a la logística de los diferentes programas, eventos y actividades de la Institución.
       `,}
      ],
    },

    "Dirección De Exportación": {
      title: "Director (a) de Exportación",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Jaime Licairac" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Planificar e implementar el plan de promoción institucional de exportación orientado a los clientes en los sectores económicos que presentan potencial de negocios en el País.
       `,}
       ,
        {text: `2. Proveer la información necesaria para la elaboración del Plan Anual de Promoción.
       `,}
       ,
        {text: `3. Potenciar el uso de herramientas que faciliten el servicio al cliente ofrecido en el área.       
       `,}
       ,
        {text: `4. Implementar las políticas internas de seguimiento y medición de resultados de los negocios captados a través de la Dirección de Exportación.
       `,}
      ],
    },
    "Gerencia De Exportación": {
      title: "Gerente de Exportación",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Segismundo Morey" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Elaborar el plan anual de promoción de las exportaciones y calendario de eventos para presentación a la Dirección de Exportación.
       `,},
       {
        text: `2. Supervisar y monitorear, el cumplimiento del plan anual para la promoción de exportaciones. 
        `,
      },
      {
        text: `3. Velar por la satisfacción de los clientes a su cargo. 
        `,
      },
      {
        text: `4. Desarrollar estrategias para el incremento y diversificación de las exportaciones, identificando escenarios a nivel local e internacional en los cuales proyectar el potencial de negocios de la oferta exportable del país.
        `,
      },
      {
        text: `5. Supervisar agendas de negocios, y servir de ente facilitador entre el exportador y el sector público, sector privado y los compradores internacionales.
         `,
      },
      {
        text: `6. Supervisar la ejecución de los eventos de promoción de las exportaciones.
       `,
      },
      {
        text: `7. Asistir a los compradores internacionales para la realización de negocios con los exportadores locales. `,
      },
      ],
    },
    "Gerencia de Internacionalización de Pymes": {
      title: "Gerente Internacionalización Pymes",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "-" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Elaborar los programas a desarrollar conjuntamente con los ejecutivos de la gerencia y otras unidades de la institución, que fomenten e impacten el desarrollo de las MiPymes potenciales exportadoras.
       `,}
       ,
        {text: `2. Elaborar con la Dirección de Exportación y el personal de la gerencia el Plan de Trabajo Anual. 
       `,}
       ,
        {text: `3. Coordinar acciones para lograr el crecimiento, la competitividad y la productividad de las MiPymes.
       `,}
       ,
        {text: `4. Propiciar la firma de acuerdos entre diversas instituciones y ProDominicana para aunar esfuerzos con miras a desarrollar programas para beneficio e incentivo de la internacionalización de las MiPymes.
       `,}
      ],
    },
    "Red Nacional": {
      title: "Gerente Red Nacional",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Francisco Peña" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Elaborar el plan anual de promoción de las exportaciones y calendario de eventos para presentación.
       `,}
       ,
        {text: `2. Supervisar y monitorear, el cumplimiento del plan anual de captación de prospectos.
       `,}
       ,
        {text: `3. Coordinar los esfuerzos para la realización de una base de datos de prospectos de exportación a nivel nacional.
       `,}
       ,
        {text: `4. Gestionar la realización de un censo nacional, por provincia y de acuerdo con los sectores productivos prioritarios.
       `,}
       ,
        {text: `5. Desarrollar estrategias para el incremento y diversificación de las exportaciones, identificando escenarios a nivel local, en donde los prospectos puedan empezar a interactuar con el comercio internacional.
       `,}
       ,
        {text: `6. Supervisar agendas de negocios, y servir de ente facilitador entre el exportador y las demás instancias del ProDominicana                      >
        .
       `,}
      ],
    },
    "Dirección De Inversión": {
      title: "Director (a) de Inversión",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Marcial Smester" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Planificar e implementar el plan de promoción de inversión institucional orientado a los clientes en los sectores económicos que presentan potencial de negocios en el país.
       `,}
       ,
        {text: `2. Proveer la información necesaria para la elaboración del Plan Anual de Promoción.
       `,}
       ,
        {text: `3. Potenciar los servicios ofrecidos para facilitar la atención a los clientes.
       `,}
       ,
        {text: `4. Implementar las políticas internas de seguimiento y medición de resultados de los negocios captados a través de la Dirección de Inversión.
       `,}
       ,
        {text: `5. Desarrollar, supervisar y evaluar al equipo técnico de la Dirección de Inversión, acorde al Plan de Promoción establecido.
       `,}
       ,
        {text: `6. Crear sinergia con las instituciones del sector público y privado vinculadas al desarrollo de proyectos de inversión por tipo de cliente, tamaño, sector, producto y mercado.
       `,}
      ],
    },

    "Servicios": {
      title: "Gerente Servicios de Inversión",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Filgia Domínguez" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Desarrollar estrategias para la atracción y captación de nuevos inversionistas, identificando escenarios a nivel local e internacional en los cuales proyectar el potencial del País en los sectores estratégicos.
       `,}
       ,
        {text: `2. Preparar, coordinar y ejecutar agendas de negocios, y servir de ente facilitador entre el inversionista y el sector público y/o privado.
       `,}
       ,
        {text: `3. Organizar y liderar eventos de promoción de potenciales inversionistas para el incremento de la IED.
       `,}
       ,
        {text: `4. Gestionar la recepción de solicitudes correspondientes a su área y coordinar el procesamiento de estas, incluyendo las solicitudes de la Ventanilla Única de Inversión de acuerdo con el Decreto No. 626-12.
       `,}
      ],
    },
    "Negocios": {
      title: "Gerente Negocios de Inversión",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Zanony Severino Normativa" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        }
       ,
        {text: `1. Elaborar el plan anual de atracción de inversión y el calendario de eventos para presentarlo a la Dirección Ejecutiva. 
       `,}
       ,
        {text: `2. Implementar y monitorear el cumplimiento del plan anual de atracción de inversión. 
       `,}
       ,
        {text: `3. Desarrollar estrategias para la atracción y captación de nuevos inversionistas, identificando escenarios a nivel local e internacional en los cuales proyectar el potencial de nuestro país en los sectores estratégicos. 
       `,}
       ,
        {text: `4. Preparar, coordinar y ejecutar agendas de negocios, servir como ente facilitador entre el inversionista y el sector público y/o privado. 
       `,}
       ,
        {text: `5. Organizar y liderar eventos de promoción de potenciales inversionistas para el incremento de la IED. 
       `,}
       ,
        {text: `6. Elaborar y mantener actualizada la carpeta de proyectos de inversión para presentarlo a la Dirección Ejecutiva.
       `,}
       ,
        {text: `7. Llevar a cabo la labor de depuración de los clientes de la gerencia, conforme a los procedimientos aprobados para estos fines.
       `,}
      ],
    },

    "Dirección Inteligencia De Mercados": {
      title: "Director (a) de Inteligencia de Mercados",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Carolina Pérez." },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Mantener actualizadas las informaciones sobre los procesos y documentos requeridos para exportar e invertir.
       `,}
       ,
        {text: `2. Poner a disposición los productos y servicios de información relevante para acceso a mercados.
      ],
       `,}
       ,
        {text: `3. Identificar oportunidades de negocios a partir del análisis comparado de regulaciones de los mercados.
      ],
       `,}
       ,
        {text: `4. Participar en los comités, comisiones y otros órganos relacionados al desarrollo del sector exportador, políticas y normas de exportación e inversión y encadenamientos.
      ],
       `,}
       ,
        {text: `5. Representar a la institución en los procesos de negociaciones sobre comercio e inversión en los que participa el país.
      ],
       `,}
       ,
        {text: `6. Asesorar y asistir en el aprovechamiento de los acuerdos comerciales vigentes, suscritos por el país.
      ],
       `,}
       ,
        {text: `7. Recomendar mejoras a políticas públicas para fortalecer el clima de negocios.
      ],
       `,}
       ,
        {text: `8. Monitorear y proponer acciones derivadas de las actividades de los organismos internacionales y órganos a los que pertenece el país.
      ],
       `,}
       ,
        {text: `9. Brindar seguimiento a temas y casos de solución de controversias Inversionista - Estad"
      ],
       `,}
      ],
    },

    "Inteligencia": {
      title: "Gerente de Inteligencia de Mercados",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "-" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Asegurar la adecuada asistencia técnica a las gerencias y unidades de la Dirección de Exportación y la Dirección de Inversión en base a las informaciones recopiladas y estudios de mercado realizados.
       `,}
       ,
        {text: `2. Asegurar la disposición de los mecanismos de información y monitoreo estratégico, con las metodologías y herramientas adecuadas que optimice la toma de decisiones.
       `,}
       ,
        {text: `3. Coordinar y dirigir las investigaciones y estudios necesarios para el cumplimiento con la misión y las funciones institucionales.
       `,}
       ,
        {text: `4. Fortalecer las capacidades analíticas de la institución y sus clientes, con la actualización permanente e implementación de mejores prácticas de Inteligencia de Mercados.
       `,}
       ,
        {text: `5. Participar de manera activa en los comités, comisiones y otros órganos relacionados a la gestión de información comercial.  
       `,}
       ,
        {text: `6. Analizar y poner a la disposición de los clientes a través del equipo de la Dirección de Exportación y la Dirección de Inversión las informaciones cuantitativas y cualitativas sobre acceso 
       `,}
       
      ],
    },
    
    "Política Económicas y Comerciales": {
      title: "Gerente de Políticas Económicas Comerciales",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Emilio Conde " },
        { title: "Normativa: ", text: "Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)" },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Mantener actualizadas las informaciones sobre los procesos y documentos requeridos para exportar e invertir. `,}
       ,
        {text: `2. Poner a disposición los productos y servicios de información relevante para acceso a mercados.`,}
       ,
        {text: `3. Identificar oportunidades de negocios a partir del análisis comparado de regulaciones de los mercados.`,}
       ,
        {text: `4. Participar en los comités, comisiones y otros órganos relacionados al desarrollo del sector exportador, políticas y normas de exportación e inversión y encadenamientos. `,}
       ,
        {text: `5. Representar a la institución en los procesos de negociaciones sobre comercio e inversión en los que participa el país. `,}
       ,
        {text: `6. Asesorar y asistir en el aprovechamiento de los acuerdos comerciales vigentes, suscritos por el país. `,}
       ,
        {text: `7. Recomendar mejoras a políticas públicas para fortalecer el clima de negocios.`,}
       ,
        {text: `8. Monitorear y proponer acciones derivadas de las actividades de los organismos internacionales y órganos a los que pertenece el país. `,}
        ,
        {text: `9. Brindar seguimiento a temas y casos de solución de controversias Inversionista - Estado y Estado - Estado.
        `,}
       , 
      ],
    },
   
    "Dirección De Marketing y Comunicación": {
      title: "Director (a) Marketing y Comunicación",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Rafaela Capriles" },
        { title: "Normativa: ", text: "Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD)" },
        {
          title: "Funciones: ",
          text: ` `,

        },
        {text: `1. Diseñar, planificar y aprobar la identidad corporativa, línea gráfica, campañas publicitarias y comunicacionales que contribuyan a elevar la imagen de la institución.
       `,}
       ,
        {text: `2. Supervisar la elaboración del Plan de Marketing y Comunicación.
       `,}
       ,
        {text: `3. Definir y establecer las estrategias de posicionamiento e imagen de la institución en los diferentes medios de comunicación.
       `,}
       ,
        {text: `4. Aprobar las campañas (medios digitales/impresos, audiovisuales) institucionales a nivel interno y externo. 
       `,}
       ,
        {text: `5. Coordinar acciones de marketing para apoyar el desarrollo de la Marca País de la República Dominicana.
       `,}
       ,
        {text: `6. Supervisar la producción audiovisual. 
       `,}
       ,
        {text: `7. Supervisión de la estrategia de marketing en redes sociales y marketing de contenidos.
       `,}
       ,
        {text: `8. Supervisar el desarrollo de las ferias y eventos institucionales.
       `,} 
      ],
    },
    "Marketing y Comunicaciones": {
      title: "Gerente de Marketing y Comunicación",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "-" },
        { title: "Normativa: ", text: "Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de laRepública Dominicana (CEI-RD)." },
        {
          title: "Funciones: ",
          text: ` `,

        },
        {text: `1. Planificar, organizar, dirigir y supervisar los planes, programas, actividades, campañas, relaciones institucionales e imagen corporativa, tanto para eventos como para ferias.
       `,}
       ,
        {text: `2. Diseñar y evaluar las estrategias de comunicación de la Institución.
       `,}
       ,
        {text: `3. Coordinar y supervisar tanto del diseño como de la consolidación de las publicaciones impresas y electrónicas, emitidas por la Institución, así como distribuirla de acuerdo con las políticas de comunicación.
       `,}
       ,
        {text: `4. Supervisar la gestión de prensa (Convocatoria de medios de comunicación, cobertura interna de actividades, elaboración y envío de notas de prensa, seguimiento a las publicaciones).
       `,}
       ,
        {text: `5. Dar seguimiento a las redes sociales de la Institución.
       `,}
       ,
        {text: `6. Realizar las maestrías de ceremonias de las actividades institucionales.
       `,}
       ,
        {text: `7. Coordinar el diseño de los materiales de promoción de la institución.
       `,}
       ,
       
,
      ],
    },

    "Eventos y Ferias": {
      title: "Gerente de Eventos y Ferias",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Carmen Ortega" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD); Decreto núm. 356-20, Art. 1." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Planificar, organizar, dirigir y supervisar los planes y programas de los eventos y ferias de la institución.  
       `,}
       ,
        {text: `2. Desarrollar un calendario de actividades, tanto para eventos internos como externos.
       `,}
       ,
        {text: `3. Diseñar un presupuesto anual para ser presentado y validado por la Dirección Ejecutiva.
       `,}
       ,
        {text: `4. Comunicar a todas las direcciones y/o departamentos implicados en las actividades, los requerimientos para que se puedan cumplir con las expectativas esperadas.
       `,}
       ,
        {text: `5. Reportar necesidades de mantenimiento y reparación de los equipos y mobiliarios necesarios para la ejecución de actividades. 
       `,}
       ,
        {text: `6. Tener control de los activos de la Institución, utilizados para las labores de promoción y eventos, en conjunto con el departamento correspondiente.
       `,}
      ],
    },

    "Producción Audiovisual": {
      title: "Gerente de Producción Audiovisual",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Aimée Martínez" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD); Decreto núm. 356-20, Art. 1." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Planificar, organizar, dirigir y supervisar los planes y las estrategias de producción determinados. 
       `,}
       ,
        {text: `2. Dar seguimiento al diseño y la producción de contenidos. 
       `,}
       ,
        {text: `3. Aprobar las piezas audiovisuales y las historias que serán publicadas.
       `,}
       ,
        {text: `4. Velar porque todos los requerimientos de Producción se cumplan, de acuerdo con la programación establecida. 
       `,}
       ,
        {text: `5. Supervisar la creación de una bitácora de las piezas creadas. 
       `,}
       ,
        {text: `6. Estructurar el guión, combinando las imágenes con la música y el diálogo.
       `,}
       ,
        {text: `7. Proponer la adquisición de equipos necesarios para la realización de las funciones del equipo. 
       `,}
      ],
    },
   
    "Gerencia de Internacionalización de Pymes": {
      title: "Gerente de Internacionalización de Pymes ",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Victor Encarnacion" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD); Decreto núm. 356-20, Art. 1." },
        {
          title: "Funciones: ",
          text: ` `,
 
        },
        {text: `1. Elaborar los programas a desarrollar conjuntamente con los ejecutivos de la gerencia y otras unidades de la institución, que fomenten e impacten el desarrollo de las MiPymes potenciales exportadoras.  `,}
       ,
        {text: `2. Elaborar con la Dirección de Exportación y el personal de la gerencia el Plan de Trabajo Anual.  `,}
       ,
       {text: `3. Coordinar acciones para lograr el crecimiento, la competitividad y la productividad de las MiPymes. `,}
       ,
        ,{text: `4. Propiciar la firma de acuerdos entre diversas instituciones y ProDominicana para aunar esfuerzos con miras a desarrollar programas para beneficio e incentivo de la internacionalización de las MiPymes. 
        `,}
       ,
      ],
    },
    "Seguridad": {
      title: "Gerente",
      imageSrc: require("./Img/anonimo.png").default,
      content: [
        { title: "Nombre: ", text: "Rafael Vladimir Martínez Tavarez" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD); Decreto núm. 356-20, Art. 1." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Planificar, ejecutar y controlar las actividades relacionadas con los procesos de prevención y seguridad de la Institución y del Director Ejecutivo.
       `,}
       ,
        {text: `2. Desarrollar estándares para la protección requerida en cada área, incluyendo procedimientos, capacitación, equipos, control de acceso de los visitantes y el cumplimiento y las comunicaciones de seguridad.
       `,}
       ,
        {text: `3. Realizar evaluaciones de riesgos de seguridad dentro de la Institución; Controlar y regular el acceso de personas, materiales y equipos a áreas vitales para el desarrollo cotidiano de la Institución mediante registro de datos personales y carnet de identificación.
        `,}
       ,
        {text: `4. Establecer e inspeccionar la ejecución de normas técnicas operativas de vigilancia y seguridad que se desarrollan en las instalaciones.
       `,}
       ,
        {text: `5. Coordinar el despliegue e instalación, cambios o sustituciones de los elementos de seguridad.
       `,}
       ,
        {text: `6. Elaborar informes técnicos en caso de presentarse alguna situación y/o conflicto en la Institución.
       `,}
       ,
        {text: `7. Controlar la entrada y salida de vehículos que ingresan al parqueo interno de la Institución mediante registro en formularios y chequeo físico de los mismos.
       `,}
       ,
        {text: `8. Velar por la seguridad de las instalaciones de la CEI-RD para preservar bienes y equipos, realizando recorridos por los mismos, detectando irregularidades e implementando mejoras; Velar por el establecimiento y aplicación de políticas de prevención e investigación de violaciones de las propiedades, colaboradores y visitantes de la Institución y actuar en caso de que ocurran esas situaciones.
       `,}
       ,
        {text: `9. Controlar mediante registro para entrada y salida de los equipos, instrumentos y cualquier otro activo de la Institución utilizado para asignaciones fuera de las instalaciones.
        `,}
       ,
        {text: `10. Coordinar con el área de Seguridad y la Gerencia de Gestión Humana un plan permanente de trato cordial a los visitantes, a fin de mantener una imagen favorable como entidad estatal de servicios.
       `,}
      ],
    },

    "Oficina De Acceso a la Información": {
      title: "Responsable",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Angélica Antigua Ramos" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD); Decreto núm. 356-20, Art. 1." },
        {
          title: "Funciones: ",
          text: ` `,
        },
        {text: `1. Recibir y tramitar las solicitudes de información por personas físicas u organizaciones, velando que las mismas sean atendidas dentro de los plazos fijados por la Ley.
       `,}
       ,
        {text: `2. Recibir y registrar las quejas, reclamos y sugerencias sobre los servicios ofrecidos por la institución y notificar al área correspondiente.
       `,}
       ,
        {text: `3. Velar por la actualización permanente de las informaciones de la institución contenidas en el Portal de Transparencia de la página web institucional, según mandato de la Ley 200-04.
       `,}
       ,
        {text: `4. Garantizar el acceso directo del público a la información básica de la Institución a través de publicaciones y medios electrónicos como internet, Páginas Web, entre otros.
       `,}
       ,
        {text: `5. Orientar a los ciudadanos o interesados con relación a los trámites y procedimientos que éstos deben seguir para solicitar la información de la Institución.
       `,}
       ,
        {text: `6. Supervisar que se entregue la información a los ciudadanos de acuerdo a los trámites y procedimientos vigentes, por los medios adecuados.
       `,}
       ,
        {text: `7. Establecer relaciones con los funcionarios de la Institución para lograr que entreguen las informaciones a tiempo y se eviten aplicación de las sanciones previstas en la Ley de Libre Acceso a la Información Pública.
       `,}
       ,
        {text: `8. Notificar a los solicitantes en caso de que la solicitud deba ser rechazada por alguna de las razones previstas en la ley, y dentro de los plazos que ésta establece.
       `,}
       ,
        {text: `9. Ordenar un sistema de archivo de las solicitudes tramitadas y rechazadas a los ciudadanos(as) y medios de comunicación, como parte de los procedimientos de la oficina de acceso a la información.
       `,}
       ,
        {text: `10. Orientar a los solicitantes respecto de otros organismos, instituciones y entidades que pudieran tener la información que solicitan.
       `,}
      ],
    },

    "Despacho Ejecutivo": {
      title: "Gerente del Despacho Ejecutivo",
      imageSrc: require("./Img/anonimo.png").default, 
      content: [
        { title: "Nombre: ", text: "Lina Pichardo" },
        { title: "Normativa: ", text: "Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la República Dominicana (CEI-RD); Decreto núm. 356-20, Art. 1." },
        {
          title: "Funciones: ",
          text: ` `,
        }
        ,
        {text: `1.	Coordinar, gestionar y dar seguimiento a las actividades establecidas por la Dirección Ejecutiva. 
        `,}
        ,
        {text: `2.	Supervisar las actividades y eventos en los cuales participe del (la) Director(a) Ejecutivo(a).  
        `,}
        ,
        {text: `3.	Dar seguimiento a la coordinación de la logística de las reuniones del Consejo Directivo. 
        `,}
        ,
        {text: `4.	Coordinar la participación del(la) Director(a) Ejecutivo(a) en los eventos nacionales e internacionales.
         `,}
        ,
        {text: `5.	Gestionar la agenda de las actividades y compromisos del (la) Director (a) Ejecutivo(a).
        `,}
        ,
        {text: `6.	Realizar el seguimiento a la documentación direccionada desde y hacia el Despacho.
        `,}
        ,
        {text: `7.	Solicitar la adquisición y administrar los presentes ofrecidos por el (la) Director(a) en sus visitas oficiales y a los visitantes que recibe en su Despacho.
         `,}
        ,
        {text: `8.	Dirigir, supervisar y evaluar la gestión del Despacho, dando seguimiento a las labores del equipo bajo su cargo.
        `,}
        ,
        {text: `9.	Acompañar eventualmente del (la) Director (a) en las visitas oficiales y reuniones de alto nivel que realice tanto en el país como en el extranjero. 
         `,}
        ,
        {text: `10.	Supervisar las labores del equipo de Seguridad (Militares) y de la Oficina de Acceso de la Información (OAI). 
        `,}
        ,
        {text: `11.	Realizar cualquier otra función afín o complementaria que le sea asignada por su superior inmediato.
        `,}
      ],
    },
    
  };


  // Función para mostrar el modal con la información correspondiente
  const handleNodeClick = (nodeLabel) => {
    const data = modalDataMap[nodeLabel];
    setModalData(data);
  };

  return (
    <MainContainer>
      <img src={Firma_y_Sello} alt="logo ProDominicana" style={{ position: "absolute", top: "20px", left: "200px" }} />

      {modalData && (
        <Modal
          title={modalData.title}
          imageSrc={modalData.imageSrc}
          content={modalData.content}
          onClose={() => setModalData(null)}
        />
      )}

      {/* Árbol del organigrama */}
      <Tree lineWidth={"3px"} lineColor={"#00a3e0"} lineBorderRadius={"12px"} label={<StyledNodeColorGreen>Consejo Directivo</StyledNodeColorGreen>}  >
        <TreeNode label={<StyledNodeColorGreen onClick={() => handleNodeClick("Dirección Ejecutiva")}>Dirección Ejecutiva</StyledNodeColorGreen>}>
            <TreeNode label={<StyledNodeColorGreen onClick={() => handleNodeClick("Subdirección General")}>Subdirección General</StyledNodeColorGreen>}>
            <TreeNode label={<StyledNodeColorGreen onClick={() => handleNodeClick("Subdirección Técnica")}>Subdirección Técnica</StyledNodeColorGreen>}>
              <TreeNode label={<StyledNodeColorGray onClick={() => handleNodeClick("Consultoría Jurídica")}>Consultoría Jurídica</StyledNodeColorGray>}>
                <TreeNode label={<StyledNode onClick={() => handleNodeClick("Subconsultor Litigios Contratos Asuntos Regulatorios")}>Subconsultor Litigios Contratos Asuntos Regulatorios</StyledNode>} /></TreeNode>
              <TreeNode label={<StyledNode onClick={() => handleNodeClick("Despacho Ejecutivo")}>Despacho Ejecutivo</StyledNode>}>
                <TreeNode label={<StyledNodeColorGray onClick={() => handleNodeClick("Dirección Innovación Estratégica")}>Dirección Innovación Estratégica</StyledNodeColorGray>}>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Coordinación Planificación Y Gestión Institucional")}>Coordinación Planificación Y Gestión Institucional</StyledNode>} />
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Gerencia de Proyectos De Innovación")}>Gerencia de Proyectos De Innovación</StyledNode>} />
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Gerencia De Tecnología de la información")}>Gerencia De Tecnología de la información</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("Coordinación de Análisis y Desarrollo")}>Coordinación de Análisis y Desarrollo</StyledNode>} />
                  </TreeNode>
                </TreeNode>
                <TreeNode label={<StyledNodeColorGray onClick={() => handleNodeClick("Dirección Relaciones Internacionales")}>Dirección Relaciones Internacionales</StyledNodeColorGray>}>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Red Internacional")}>Red Internacional</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("Enlaces Representantes De Secciones Comerciales")}>Enlaces Representantes De Secciones Comerciales</StyledNode>}>
                      <TreeNode label={<StyledNode onClick={() => handleNodeClick("Gestores Comerciales")}>Gestores Comerciales</StyledNode>} />
                      <TreeNode label={<StyledNode onClick={() => handleNodeClick("Representantes Internacionales")}>Representantes Internacionales</StyledNode>} />
                    </TreeNode>
                  </TreeNode>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Cooperación Internacional")}>Cooperación Internacional</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("Análisis de Cooperación")}>Análisis de Cooperación</StyledNode>} />
                  </TreeNode>
                </TreeNode>
                <TreeNode label={<StyledNodeColorGray onClick={() => handleNodeClick("Dirección Del Talento Humano y Servicio")}>Dirección Del Talento Humano y Servicio</StyledNodeColorGray>}>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Gestión del Talento Humano")}>Gestión del Talento Humano</StyledNode>} />
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Centro De Atención al Cliente")}>Centro De Atención al Cliente</StyledNode>} />
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Formación y Desarrollo (Interna y Externa)")}>Formación y Desarrollo (Interna y Externa)</StyledNode>} />
                </TreeNode>
                <TreeNode label={<StyledNodeColorGray onClick={() => handleNodeClick("Dirección Administrativa y Financiera")}>Dirección Administrativa y Financiera</StyledNodeColorGray>}>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Administrativa")}>Administrativa</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("- Compras - Activos Fijos y Almacén - Archivo y Correspondencia")}>- Compras - Activos Fijos y Almacén - Archivo y Correspondencia</StyledNode>} />
                  </TreeNode>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Financiera")}>Financiera</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("Contabilidad Presupuesto")}>Contabilidad Presupuesto</StyledNode>} />
                  </TreeNode>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Servicios Generales")}>Servicios Generales</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("Mantenimiento Servicios Logísticos: Trasportación y Mayordomía")}>Mantenimiento Servicios Logísticos: Trasportación y Mayordomía</StyledNode>} />
                  </TreeNode>
                </TreeNode>
                <TreeNode label={<StyledNodeColorGray onClick={() => handleNodeClick("Dirección De Exportación")}>Dirección De Exportación</StyledNodeColorGray>}>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Gerencia De Exportación")}>Gerencia De Exportación</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("Unidad De Certificaciones al Exportador Unidad De Negocios Unidad De Atracción Servicios Modernos De Exportación")}>Unidad De Certificaciones al Exportador Unidad De Negocios Unidad De Atracción Servicios Modernos De Exportación</StyledNode>} />
                  </TreeNode>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Gerencia de Internacionalización de Pymes")}>Gerencia de Internacionalización de Pymes</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("Ejecutivo Pymes")}>Ejecutivo Pymes</StyledNode>} />
                  </TreeNode>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Red Nacional")}>Red Nacional</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("Ejecutivo Red Nacional")}>Ejecutivo Red Nacional</StyledNode>} />
                  </TreeNode>
                </TreeNode>
                <TreeNode label={<StyledNodeColorGray onClick={() => handleNodeClick("Dirección De Inversión")}>Dirección De Inversión</StyledNodeColorGray>}>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Servicios")}>Servicios</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("Registro De Inversión Extranjera Ventanilla Única De Inversión")}>Registro De Inversión Extranjera Ventanilla Única De Inversión</StyledNode>} />
                  </TreeNode>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Negocios")}>Negocios</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("- New Business After Care Unidad De Atracción De Servicios Moderno De Inversión")}>- New Business After Care Unidad De Atracción De Servicios Moderno De Inversión</StyledNode>} />
                  </TreeNode>
                </TreeNode>
                <TreeNode label={<StyledNodeColorGray onClick={() => handleNodeClick("Dirección Inteligencia De Mercados")}>Dirección Inteligencia De Mercados</StyledNodeColorGray>}>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Política Económicas y Comerciales")}>Política Económicas y Comerciales</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("- Unidad De Políticas Comerciales - Unidad De Proyectos Especiales")}>- Unidad De Políticas Comerciales - Unidad De Proyectos Especiales</StyledNode>} />
                  </TreeNode>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Inteligencia")}>Inteligencia</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("-Inteligencia De Exportación -Inteligencia De Inversión")}>-Inteligencia De Exportación -Inteligencia De Inversión</StyledNode>} />
                  </TreeNode>
                </TreeNode>
                <TreeNode label={<StyledNodeColorGray onClick={() => handleNodeClick("Dirección De Marketing y Comunicación")}>Dirección De Marketing y Comunicación</StyledNodeColorGray>}>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Marketing y Comunicaciones")}>Marketing y Comunicaciones</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("- Diseño Gráfico - Comunicación")}>- Diseño Gráfico - Comunicación</StyledNode>} />
                  </TreeNode>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Eventos y Ferias")}>Eventos y Ferias</StyledNode>}>
                    <TreeNode label={<StyledNode onClick={() => handleNodeClick("Eventos Institucionales Ferias")}>Eventos Institucionales Ferias</StyledNode>} />
                  </TreeNode>
                  <TreeNode label={<StyledNode onClick={() => handleNodeClick("Producción Audiovisual")}>Producción Audiovisual</StyledNode>} />
                </TreeNode>
                <TreeNode label={<StyledNode onClick={() => handleNodeClick("Seguridad")}>Seguridad</StyledNode>} />
                <TreeNode label={<StyledNode onClick={() => handleNodeClick("Marca País")}>Marca País</StyledNode>} />
                <TreeNode label={<StyledNode onClick={() => handleNodeClick("Oficina De Acceso a la Información")}>Oficina De Acceso a la Información</StyledNode>} />
              </TreeNode>
            </TreeNode>
          </TreeNode>
        </TreeNode> 
    </Tree>
      </MainContainer>
  );
};

export default OrganizationalChart;