import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import OrganizationalChart from './OrganizationalChart';
import styled from 'styled-components';


const MainContainer = styled.h2`
  top: 20px;
`; 
ReactDOM.render(
	<React.StrictMode>
      <OrganizationalChart />
	</React.StrictMode>,
document.getElementById('root')
);

