import React, {useState} from 'react';
import './index.css';
import styled from 'styled-components';
import Modal from './Components/Modal';

const App = () => {
	const [estadoModal1, cambiarEstadoModal1]= useState(false);
	const [estadoModal2, cambiarEstadoModal2]= useState(false);
	const [estadoModal3, cambiarEstadoModal3]= useState(false);
	const [estadoModal4, cambiarEstadoModal4]= useState(false);
	const [estadoModal5, cambiarEstadoModal5]= useState(false);
	const [profileImage, setProfileImage] = useState('https://prodominicana.gob.do/Imagenes/Biviana%20Riveiro.jpg');
	return (
		<>
		<div>
			<ContenedorBotones>
				<Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>Modal 1</Boton>
				<Boton onClick={() => cambiarEstadoModal2(!estadoModal2)}>Modal 2</Boton>
				<Boton onClick={() => cambiarEstadoModal3(!estadoModal3)}>Modal 3</Boton>
				<Boton onClick={() => cambiarEstadoModal4(!estadoModal4)}>Modal 3</Boton>
				<Boton onClick={() => cambiarEstadoModal5(!estadoModal5)}>Modal 5</Boton>
			</ContenedorBotones>

			{/*Contenido modal 1*/}
			<Modal
			estado={estadoModal1}
			cambiarEstado={cambiarEstadoModal1}
			titulo="Director Ejecutivo"
			>
				<Contenido>
				<Column>
            <ProfileImage src={profileImage} alt="Imagen de perfil"/>
          </Column>
					<Column>
				<h1>Consultor (a) Jurídico (a): Anel Lluberes </h1>
				<p>

Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la
República Dominicana (CEI-RD).

Funciones principales:                                                                                                                                                                                                                         1. Velar por la correcta aplicación de las disposiciones legales en la tramitación de los asuntos de su competencia.
2. Realizar investigaciones y análisis en el orden jurídico, para resolver asuntos legales propios de la institución.
3. Preparar, redactar, analizar y evaluar la documentación requerida por las diferentes Instancias internas y externas.
4. Elaborar contratos a ser suscritos por el representante de la institución con personas físicas o morales, redacción de leyes, decretos, reglamentos y llevar registro y control de los mismos.
5. Intervenir en las reclamaciones y litigios que puedan afectar los intereses de la institución.
</p>
</Column>
				<Boton onClick={() => cambiarEstadoModal1(!estadoModal1)}>Aceptar</Boton>
				</Contenido>
			</Modal>

			{/*Contenido modal 2*/}
			<Modal
			estado={estadoModal2}
			cambiarEstado={cambiarEstadoModal2}
			titulo="Director Ejecutivo"
			>
				<Contenido>
				<h3>Consultor (a) Jurídico (a): Anel Lluberes </h3>
				<p>

Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la
República Dominicana (CEI-RD).

Funciones principales:                                                                                                                                                                                                                         1. Velar por la correcta aplicación de las disposiciones legales en la tramitación de los asuntos de su competencia.
2. Realizar investigaciones y análisis en el orden jurídico, para resolver asuntos legales propios de la institución.
3. Preparar, redactar, analizar y evaluar la documentación requerida por las diferentes Instancias internas y externas.
4. Elaborar contratos a ser suscritos por el representante de la institución con personas físicas o morales, redacción de leyes, decretos, reglamentos y llevar registro y control de los mismos.
5. Intervenir en las reclamaciones y litigios que puedan afectar los intereses de la institución.
</p>
				<Boton onClick={() => cambiarEstadoModal2(!estadoModal2)}>Aceptar</Boton>
				</Contenido>
			</Modal>

			{/*Contenido modal 3*/}
			<Modal
			estado={estadoModal3}
			cambiarEstado={cambiarEstadoModal3}
			titulo="Director Ejecutivo"
			>
				<Contenido>
				<h1>Consultor (a) Jurídico (a): Anel Lluberes </h1>
				<p>

Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la
República Dominicana (CEI-RD).

Funciones principales:                                                                                                                                                                                                                         1. Velar por la correcta aplicación de las disposiciones legales en la tramitación de los asuntos de su competencia.
2. Realizar investigaciones y análisis en el orden jurídico, para resolver asuntos legales propios de la institución.
3. Preparar, redactar, analizar y evaluar la documentación requerida por las diferentes Instancias internas y externas.
4. Elaborar contratos a ser suscritos por el representante de la institución con personas físicas o morales, redacción de leyes, decretos, reglamentos y llevar registro y control de los mismos.
5. Intervenir en las reclamaciones y litigios que puedan afectar los intereses de la institución.

Funciones principales:                                                                                                                                                                                                                         1. Velar por la correcta aplicación de las disposiciones legales en la tramitación de los asuntos de su competencia.
2. Realizar investigaciones y análisis en el orden jurídico, para resolver asuntos legales propios de la institución.
3. Preparar, redactar, analizar y evaluar la documentación requerida por las diferentes Instancias internas y externas.
4. Elaborar contratos a ser suscritos por el representante de la institución con personas físicas o morales, redacción de leyes, decretos, reglamentos y llevar registro y control de los mismos.
5. Intervenir en las reclamaciones y litigios que puedan afectar los intereses de la institución.

Funciones principales:                                                                                                                                                                                                                         1. Velar por la correcta aplicación de las disposiciones legales en la tramitación de los asuntos de su competencia.
2. Realizar investigaciones y análisis en el orden jurídico, para resolver asuntos legales propios de la institución.
3. Preparar, redactar, analizar y evaluar la documentación requerida por las diferentes Instancias internas y externas.
4. Elaborar contratos a ser suscritos por el representante de la institución con personas físicas o morales, redacción de leyes, decretos, reglamentos y llevar registro y control de los mismos.
5. Intervenir en las reclamaciones y litigios que puedan afectar los intereses de la institución.
</p>
				<Boton onClick={() => cambiarEstadoModal3(!estadoModal3)}>Aceptar</Boton>
				</Contenido>
			</Modal>

			{/*Contenido modal 4*/}
			<Modal
			estado={estadoModal4}
			cambiarEstado={cambiarEstadoModal4}
			titulo="Director Ejecutivo"
			>
				<Contenido>
				<h1>Consultor (a) Jurídico (a): Anel Lluberes </h1>
				<p>

Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la
República Dominicana (CEI-RD).

Funciones principales:                                                                                                                                                                                                                         1. Velar por la correcta aplicación de las disposiciones legales en la tramitación de los asuntos de su competencia.
2. Realizar investigaciones y análisis en el orden jurídico, para resolver asuntos legales propios de la institución.
3. Preparar, redactar, analizar y evaluar la documentación requerida por las diferentes Instancias internas y externas.
4. Elaborar contratos a ser suscritos por el representante de la institución con personas físicas o morales, redacción de leyes, decretos, reglamentos y llevar registro y control de los mismos.
5. Intervenir en las reclamaciones y litigios que puedan afectar los intereses de la institución.
</p>
				<Boton onClick={() => cambiarEstadoModal4(!estadoModal4)}>Aceptar</Boton>
				</Contenido>
			</Modal>

			{/*Contenido modal 5*/}
			<Modal
			estado={estadoModal5}
			cambiarEstado={cambiarEstadoModal5}
			titulo="Director Ejecutivo"
			>
				<Contenido>
				<h1>Consultor (a) Jurídico (a): Anel Lluberes </h1>
				<p>

Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la
República Dominicana (CEI-RD).

Funciones principales:                                                                                                                                                                                                                         1. Velar por la correcta aplicación de las disposiciones legales en la tramitación de los asuntos de su competencia.
2. Realizar investigaciones y análisis en el orden jurídico, para resolver asuntos legales propios de la institución.
3. Preparar, redactar, analizar y evaluar la documentación requerida por las diferentes Instancias internas y externas.
4. Elaborar contratos a ser suscritos por el representante de la institución con personas físicas o morales, redacción de leyes, decretos, reglamentos y llevar registro y control de los mismos.
5. Intervenir en las reclamaciones y litigios que puedan afectar los intereses de la institución.
</p>
				<Boton onClick={() => cambiarEstadoModal4(!estadoModal4)}>Aceptar</Boton>
				</Contenido>
			</Modal>{/*Contenido modal 5*/}
			<Modal
			estado={estadoModal5}
			cambiarEstado={cambiarEstadoModal5}
			titulo="Director Ejecutivo"
			>
				<Contenido>
				<Column>
            <ProfileImage src={profileImage} alt="Imagen de perfil"/>
          </Column>
					<Column>
				<h1>Consultor (a) Jurídico (a): Anel Lluberes </h1>
				<p>

Normativa: Ley núm. ley 16-92, Código de trabajo; Ley núm. 98-03 que crea el Centro de Exportación e Inversión de la
República Dominicana (CEI-RD).

Funciones principales:                                                                                                                                                                                                                         1. Velar por la correcta aplicación de las disposiciones legales en la tramitación de los asuntos de su competencia.
2. Realizar investigaciones y análisis en el orden jurídico, para resolver asuntos legales propios de la institución.
3. Preparar, redactar, analizar y evaluar la documentación requerida por las diferentes Instancias internas y externas.
4. Elaborar contratos a ser suscritos por el representante de la institución con personas físicas o morales, redacción de leyes, decretos, reglamentos y llevar registro y control de los mismos.
5. Intervenir en las reclamaciones y litigios que puedan afectar los intereses de la institución.
</p>
</Column>
				<Boton onClick={() => cambiarEstadoModal5(!estadoModal5)}>Aceptar</Boton>
				</Contenido>
			</Modal>
		</div>
		
		</>
	);
}
 
export default App;

const ContenedorBotones = styled.div`
	padding: 40px;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 20px;
`;

const Boton = styled.button`
	display: block;
	padding: 10px 30px;
	border-radius: 100px;
	color: #fff;
	border: none;
	background: #1766DC;
	cursor: pointer;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	transition: .3s ease all;

	&:hover {
		background: #0066FF;
	}
`;

const Contenido = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	h1 {
		font-size: 42px;
		font-weight: 700;
		margin-bottom: 10px;
	}

	p {
		font-size: 16px;
		margin-bottom: 20px;
	}

	img {
		width: 100%;
		vertical-align: top;
		border-radius: 3px;
	}
`;

const Column = styled.div`
  flex: 1;
`;

const ProfileImage = styled.img`
  width: 100%;
  max-width: 150px;
  border-radius: 50%;
`;

  
