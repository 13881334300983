import React from 'react';
import styled from 'styled-components';

const Modal = ({children, estado, cambiarEstado, titulo}) => {
return(
<>
{ estado &&

<Overlay>
    <ContenedorModal>
        <EncabezadoModal>
            <h1>{titulo}</h1>
            <BotonCerrar onClick={() => cambiarEstado(false)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg></BotonCerrar> 
        </EncabezadoModal>
        <Column>
{children}
</Column>
    </ContenedorModal>
</Overlay>
}

</>

);

}


export default Modal;

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0,0,0,.5);

    padding: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

`;

const ContenedorModal = styled.div`
    width: 500px;
    min-height: 600px;
    background: #fff;
    possition: relative;
    border-radius: 5px;
    box-shadow: rgba(100,100,111, 0.2) 0px 7px 29px 0px;
    padding: 20px;
    border-top: 1px solid #E8E8E8;

    ul.list
{
	list-style: none;
}
ul.list li
{
	margin: 5px 0;
}
ul.list li>strong
{
	display: block;
	text-transform: uppercase;
	color:#003579;
	margin:10px 0;
}
ul.list li span.nodeFunciones {
    white-space: pre-wrap;
}   
`;


const EncabezadoModal =styled.div`
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 20px;
padding-bottom: 20px 
 border-top: 1px dashed red;

h1 {
    font-weight: 500;
    font-size: 24px;
    color: #1766DC;
}

`;

const LineaHorizontal =styled.div`

hr {
    height: 10px;
    width: 100%;
    background-color: black;
  }
`;

const BotonCerrar = styled.button`

top: 15px;
right: 20px;


width: 30px;
height: 30px;
border: none;
background: none;
cursor: pointer;
transition: .3s ease all;
border-radius: 5px;
color: #1766DC;

&:hover {
    background: #f2f2f2;

}

svg {
    width: 30px;
    height: 30px;

}
`;

const Column = styled.div`
  flex: 1;
`;

const ProfileImage = styled.img`
  width: 100%;
  max-width: 150px;
  border-radius: 50%;
`;

